export const zipCode = [
  '96701',
  '96703',
  '96704',
  '96705',
  '96706',
  '96707',
  '96708',
  '96709',
  '96710',
  '96712',
  '96713',
  '96714',
  '96715',
  '96716',
  '96717',
  '96718',
  '96719',
  '96720',
  '96721',
  '96722',
  '96725',
  '96726',
  '96727',
  '96728',
  '96729',
  '96730',
  '96731',
  '96732',
  '96733',
  '96734',
  '96737',
  '96738',
  '96739',
  '96740',
  '96741',
  '96742',
  '96743',
  '96744',
  '96745',
  '96746',
  '96747',
  '96748',
  '96749',
  '96750',
  '96751',
  '96752',
  '96753',
  '96754',
  '96755',
  '96756',
  '96757',
  '96759',
  '96760',
  '96761',
  '96762',
  '96763',
  '96764',
  '96765',
  '96766',
  '96767',
  '96768',
  '96769',
  '96770',
  '96771',
  '96772',
  '96773',
  '96774',
  '96776',
  '96777',
  '96778',
  '96779',
  '96780',
  '96781',
  '96782',
  '96783',
  '96784',
  '96785',
  '96786',
  '96788',
  '96789',
  '96790',
  '96791',
  '96792',
  '96793',
  '96795',
  '96796',
  '96797',
  '96801',
  '96802',
  '96803',
  '96804',
  '96805',
  '96806',
  '96807',
  '96808',
  '96809',
  '96810',
  '96811',
  '96812',
  '96813',
  '96814',
  '96815',
  '96816',
  '96817',
  '96818',
  '96819',
  '96820',
  '96821',
  '96822',
  '96823',
  '96824',
  '96825',
  '96826',
  '96827',
  '96828',
  '96830',
  '96835',
  '96836',
  '96837',
  '96838',
  '96839',
  '96840',
  '96841',
  '96843',
  '96844',
  '96846',
  '96847',
  '96848',
  '96849',
  '96850',
  '96853',
  '96854',
  '96857',
  '96858',
  '96859',
  '96860',
  '96861',
  '96863',
  '96898',
];
