import React from 'react';
import { Text, View } from 'src/components/common';
import Logo from 'src/components/Logo';
import cn from 'classnames';

const Header = () => {
  return (
    <View fullWidth justify="center" align="center" className="mb-32">
      <View>
        <Logo className="mb-16" />
      </View>

      <Text size={16} className={cn('text-color-grey-900')}>
        Welcome to
      </Text>
      <Text size={20} className={cn('fw-bold  text-color-grey-900')}>
        Disability Compensation Division
      </Text>
    </View>
  );
};

export default Header;
