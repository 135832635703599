import { PORTAL } from 'src/containers/UAMContainer/employer/Signup/helpers';

export const getRoutePortal = (portal: PORTAL) => {
  switch (portal) {
    case PORTAL.ADJUSTER:
      return '/adjuster';
    case PORTAL.EMPLOYER:
      return '/employer';
    case PORTAL.CLAIMANT:
      return '/claimant';
    case PORTAL.HEALTH_CARE_CONTRACTOR:
      return '/hcc';
    case PORTAL.INSURANCE_CARRIER:
      return '/carrier';
    case PORTAL.PHYSICIAN_IND:
    case PORTAL.PHYSICIAN_ORG:
      return '/physician';
    case PORTAL.AGENT_ORG:
    case PORTAL.REPRESENTATIVE_IND:
      return '/representative';
    case PORTAL.ATTORNEY_EMPLOYER:
      return '/AttorneyEmployer';
    case PORTAL.ATTORNEY_CLAIMANT:
      return '/AttorneyClaimant';
    default:
      return '/';
  }
};
