export enum FORM_WC_KEY {
  WC_1 = 'wc-1',
  WC_2 = 'wc-2',
  WC_3 = 'wc-3',
  WC_3a = 'wc-3a',
  WC_5 = 'wc-5',
  WC_5a = 'wc-5a',
}

export const workersCompensationOptions = [
  {
    id: FORM_WC_KEY.WC_1,
    label: `Employer’s Report of Industrial Injury`,
    url: '#',
  },
  {
    id: FORM_WC_KEY.WC_2,
    label: `Physician’s Report`,
    url: '#',
  },
  {
    id: FORM_WC_KEY.WC_3a,
    label: `Carrier’s Benefit Adjustment Report`,
    url: '#',
  },
  {
    id: FORM_WC_KEY.WC_3,
    label: `Carrier’s Case Report`,
    url: '#',
  },
  {
    id: FORM_WC_KEY.WC_5,
    label: `Employee’s Claim for Workers’ Compensation Benefits`,
    url: '#',
  },
  {
    id: FORM_WC_KEY.WC_5a,
    label: `Dependent's  Claim for Workers Compensation`,
    url: '#',
  },
];
