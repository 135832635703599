import MUIDataTable, {
  debounceSearchRender,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from 'mui-datatables';
import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { createMuiTheme, MuiThemeProvider, ThemeOptions } from '@material-ui/core';
import { IRootState } from 'src/redux/rootReducer';
import { LoadingCommon, View } from 'src/components/common';
import './styles.scss';

const TableBasic: React.FC<Props> = ({
  isLoading,
  containerClassName,
  onTableChange,
  currentPage,
  options,
  total,
  emptyComponent = 'No data',
  data,
  ...props
}) => {
  const tableOptions: MUIDataTableOptions = {
    serverSide: true,
    searchOpen: false,
    search: true,
    download: false,
    filter: true,
    print: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: isLoading ? <LoadingCommon /> : emptyComponent,
      },
    },
    jumpToPage: false,
    rowHover: true,
    onTableChange,
    customSearchRender: debounceSearchRender(500),
    ...options,
  };

  const hasRowClickAction = !!options?.onRowClick;
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiPaper: {
          elevation4: {
            boxShadow: 'none',
          },
        },
        MuiTableRow: {
          hover: {
            cursor: hasRowClickAction ? 'pointer' : 'default',
          },
        },
        MuiTableCell: {
          root: {
            padding: '16px 24px',
          },
        },
        MuiTableBody: {
          root: {
            opacity: isLoading ? 0.3 : 1,
          },
        },
        MuiPopover: {
          paper: {
            minWidth: 300,
          },
        },
        MuiToolbar: {},
        MUIDataTableSearch: {
          main: {
            '& button': {
              display: 'none !important',
            },
            '& svg': {
              transform: 'translateY(0px) !important',
              marginTop: '0px !important',
            },
            // border: '1px solid #CACED0 !important',
            padding: '2px 8px !important',
            borderRadius: '4px !important',
            alignItems: 'center !important',
          },
          searchText: {
            // flex: '1 0 !important',
          },
        },
      },
    } as ThemeOptions);

  const muiDataTable = useMemo(
    () => {
      return <MUIDataTable options={tableOptions} data={data} {...props} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // More info: https://github.com/gregnb/mui-datatables
  return (
    <View className={cn('cmp-table-basic', containerClassName)} flexGrow={1}>
      <MuiThemeProvider theme={getMuiTheme()}>
        {muiDataTable}
        {/* <MUIDataTable options={tableOptions} {...props} /> */}
      </MuiThemeProvider>
    </View>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  MUIDataTableProps & {
    containerClassName?: string;
    currentPage?: number;
    total?: number;
    onTableChange: (action: string, tableState: MUIDataTableState) => void;
    isLoading?: boolean;
    emptyComponent?: React.ReactNode;
  };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(memo(TableBasic));
