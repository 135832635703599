import React from 'react';
import { TenantService } from 'src/services';
import EmployerWelcome from '../employer/Welcome';

const Welcome: React.FC<Props> = (props) => {
  if (TenantService.isEmployer()) return <EmployerWelcome {...props} />;
  return null;
};

type Props = {};

export default Welcome;
