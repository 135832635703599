import cn from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { IRootState } from 'src/redux/rootReducer';
import { Callback } from 'src/redux/types';
import { Image, View } from '../common';
import './styles.scss';

const clsPrefix = 'cmp-logo';

export const LogoName = {
  title: 'Unemployment Insurance',
  subTitle: 'State of Hawaii, Department of Labor and Industrial Relations',
};

const Logo: React.FC<Props> = ({ className = '', logoSize = 150, onClick }) => {
  return (
    <View className={cn(clsPrefix, className, { 'cursor-pointer': !!onClick })} onClick={onClick}>
      <Image
        src={IMAGES.dcdLogo}
        className={`${clsPrefix}__img`}
        width={logoSize}
        height={logoSize}
      />
    </View>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    className?: string;
    logoSize?: string | number;
    onClick?: Callback;
  };
const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
