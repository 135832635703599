import cn from 'classnames';
import { Formik, FormikProps } from 'formik';
import { History, Location } from 'history';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Grid, Input, NavLink, Text, View } from 'src/components/common';
import Form from 'src/components/common/Form';
import { IRootState } from 'src/redux/rootReducer';

import { PATHS } from 'src/appConfig/paths';
import { useComponentDidMount } from 'src/hooks';
import { ForgotPasswordPayload, useForgotPassword, useResendSignUp } from 'src/queries';
import { ErrorService, Navigator, Toastify, Yup } from 'src/services';
import { getLocationState } from 'src/utils';

import { hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { UAMBody } from '../../common';
import EmailConfirmationModal from '../../common/EmailConfirmationModal';

import '../../Signin/styles.scss';

type FormValue = {
  email: string;
};

const INITIAL = {
  email: '',
};

const ForgotPassword: React.FC<Props> = ({ location, onHideDialog, onShowDialog }) => {
  const formRef = useRef<FormikProps<FormValue>>(null);

  const [emailSent, setEmailSent] = useState('');
  const { resendSignUp } = useResendSignUp();

  useComponentDidMount(() => {
    const state = getLocationState(location);
    if (state?.email) {
      formRef.current.setValues({ email: state.email as string });
    }
  });

  const handleError = (error: AuthError, variables: ForgotPasswordPayload) => {
    switch (error.code) {
      case 'InvalidParameterException':
        resendSignUp({ username: variables.email });
        return onShowDialog({
          type: DIALOG_TYPES.CONTENT_DIALOG,
          data: {
            content: (
              <EmailConfirmationModal
                username={variables.email}
                onConfirmSuccess={() => {
                  onHideDialog();
                  Navigator.navigate(PATHS.signIn, { email: variables.email });
                }}
              />
            ),
            hideTitle: true,
          },
        });
      default:
        return ErrorService.handler(error);
    }
  };

  const { forgotPassword, isLoading } = useForgotPassword({
    onSuccess(data, variables, context) {
      setEmailSent(variables.email);
      if (emailSent) Toastify.success('A new link has been sent to your email.');
      Navigator.navigate(`${PATHS.resetPassword}?email=${encodeURIComponent(variables.email)}`);
    },
    onError(error, variables, context) {
      handleError(error, variables);
    },
  });

  // =========================== FORGOT PASSWORD ===========================
  const handleSubmitForgotPassword = (values: FormValue) => {
    const { email } = values;
    forgotPassword({ email: email });
  };

  // =========================== SCHEMA ===========================
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required().email(),
  });

  return (
    <UAMBody>
      <Text size={36} className={cn('fw-medium mb-8 text-color-grey-900 text-align-center')}>
        {'Reset Your Password'}
      </Text>

      <Text className={cn('mb-16 text-color-grey-900')}>
        {
          'Please enter the email associated with your account and we’ll send you instructions to reset your password.'
        }
      </Text>

      <Formik
        initialValues={INITIAL}
        onSubmit={handleSubmitForgotPassword}
        validationSchema={ForgotPasswordSchema}
        innerRef={formRef}
      >
        {({ errors, touched, getFieldProps, handleSubmit }) => (
          <Form onSubmit={handleSubmit} autoComplete="off" className="ctn-uam__form">
            <Grid.Wrap>
              <Grid.Item variant="is-full">
                <Input
                  label="Email Address"
                  required
                  placeholder="Email Address"
                  errorMessage={touched.email ? errors.email : ''}
                  {...getFieldProps('email')}
                />
              </Grid.Item>
              <Grid.Item variant="is-full">
                <View flexGrow={1}>
                  <Button
                    type="submit"
                    variant="secondary"
                    className="fw-medium my-12"
                    isLoading={isLoading}
                  >
                    Continue
                  </Button>
                </View>
              </Grid.Item>
            </Grid.Wrap>
          </Form>
        )}
      </Formik>

      <Text className={cn('my-2', { 'text-center': !emailSent })}>
        <NavLink className={'fw-medium text-is-16'} to={PATHS.signIn}>
          Back to Log In
        </NavLink>
      </Text>
    </UAMBody>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & { history: History; location: Location<string> };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
