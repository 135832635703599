import React from 'react';
import { TenantService } from 'src/services';
import EmployerForgotPassword from '../employer/ForgotPassword';
import { History, Location } from 'history';

const ForgotPassword: React.FC<Props> = (props) => {
  if (TenantService.isEmployer()) return <EmployerForgotPassword {...props} />;
  return null;
};

type Props = { history: History; location: Location<string> };

export default ForgotPassword;
