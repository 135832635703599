import React from 'react';
import Text from '../Text';

const PasswordSharingMessage: React.FC = () => {
  return (
    <Text className="has-text-primary mt-4" size={14}>
      User agrees to use this portal for lawful purposes only for the State of Hawaii’s Worker’s
      Compensation, Temporary Disability Insurance and Prepaid Healthcare claims, filings, and
      insurance policy business. User shall not submit or provide false or inaccurate claims or
      information. The State of Hawai’i may take necessary action to protect its rights, and the
      rights of others.
    </Text>
  );
};

export default PasswordSharingMessage;
