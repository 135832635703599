import React from 'react';
import { TenantService } from 'src/services';
import EmployerResetPassword from '../employer/ResetPassword';
import { History, Location } from 'history';

const ResetPassword: React.FC<Props> = (props) => {
  if (TenantService.isEmployer()) return <EmployerResetPassword {...props} />;
  return null;
};

type Props = { history: History; location: Location<string> };

export default ResetPassword;
