import React from 'react';
import { TenantService } from 'src/services';
import EmployerSignin from '../employer/Signin';
import { History } from 'history';
import './styles.scss';
const SignIn: React.FC<Props> = (props) => {
  if (TenantService.isEmployer()) return <EmployerSignin {...props} />;
  return null;
};

type Props = { history: History };

export default SignIn;
